function useConfirmHelpers() {
  const { $toast } = useNuxtApp()

  const confirmVisible = ref<boolean>(false)

  const selAction = ref<string | null>(null)

  const confirmLabel = ref<string>('Are you sure you want to perform this action?')

  const confirmYes = ref((callback: () => void) => {
    try {
      callback()
    }
    catch (e) {
      $toast.addToast({
        title: 'Error',
        message: e instanceof Error ? e.message : (typeof e === 'string' ? e : 'An error occurred'),
        type: 'danger',
      })
    }
    confirmVisible.value = false
  })

  const confirmNo = () => {
    confirmVisible.value = false
    confirmYes.value = () => { }
  }

  const showConfirm = (callback: () => void, action: Actions | null = null) => {
    confirmYes.value = (callback)
    confirmVisible.value = true
    selAction.value = action
  }

  return {
    // variables
    confirmVisible,
    confirmLabel,

    // methods
    confirmYes,
    confirmNo,
    showConfirm,
    selAction,
  }
}

export default useConfirmHelpers
